import React from 'react'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layouts'

export const query = graphql`
{
  prismic {
    privacy_policy (uid: "terms-and-conditions", lang: "en-ca") {
      termsAndConditions
    }
  }
}
`

const RenderBody = ({ termsAndConditions }) => (
  <React.Fragment>
    {RichText.render(termsAndConditions)}
  </React.Fragment>
)


export default ({ data }) => {
  if (!data) return null
  const doc = data.prismic.privacy_policy;


  if(!doc) return null;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>{RichText.asText(doc.node.title)}</title> */}
      </Helmet>
      <RenderBody termsAndConditions={doc.termsAndConditions} />
    </Layout>
  );
}

